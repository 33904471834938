@font-face {
    font-family: 'Optum Sans';
    src: url('custom/fonts/OptumSans/OptumSans-RegularItalic.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSans-RegularItalic.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSans-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans Cond';
    src: url('custom/fonts/OptumSans/OptumSansCond-BoldItalic.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSansCond-BoldItalic.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSansCond-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans';
    src: url('custom/fonts/OptumSans/OptumSans-Bold.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSans-Bold.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans';
    src: url('custom/fonts/OptumSans/OptumSans-XBold.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSans-XBold.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSans-XBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans Cond';
    src: url('custom/fonts/OptumSans/OptumSansCond-RegularItalic.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSansCond-RegularItalic.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSansCond-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans';
    src: url('custom/fonts/OptumSans/OptumSans-BoldItalic.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSans-BoldItalic.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans Cond';
    src: url('custom/fonts/OptumSans/OptumSansCond-Bold.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSansCond-Bold.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSansCond-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans';
    src: url('custom/fonts/OptumSans/OptumSans-Regular.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSans-Regular.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans Cond';
    src: url('custom/fonts/OptumSans/OptumSansCond-Regular.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSansCond-Regular.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSansCond-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans';
    src: url('custom/fonts/OptumSans/OptumSans-BoldItalic_1.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSans-BoldItalic_1.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSans-BoldItalic_1.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans';
    src: url('custom/fonts/OptumSans/OptumSans-Bold_1.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSans-Bold_1.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSans-Bold_1.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans';
    src: url('custom/fonts/OptumSans/OptumSans-Regular_1.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSans-Regular_1.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSans-Regular_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans XBold';
    src: url('custom/fonts/OptumSans/OptumSans-XBold_1.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSans-XBold_1.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSans-XBold_1.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans Cond';
    src: url('custom/fonts/OptumSans/OptumSansCond-Bold_1.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSansCond-Bold_1.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSansCond-Bold_1.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans';
    src: url('custom/fonts/OptumSans/OptumSans-RegularItalic_1.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSans-RegularItalic_1.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSans-RegularItalic_1.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans Cond';
    src: url('custom/fonts/OptumSans/OptumSansCond-RegularItalic_1.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSansCond-RegularItalic_1.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSansCond-RegularItalic_1.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans Cond';
    src: url('custom/fonts/OptumSans/OptumSansCond-BoldItalic_1.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSansCond-BoldItalic_1.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSansCond-BoldItalic_1.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans Cond';
    src: url('custom/fonts/OptumSans/OptumSansCond-Regular_1.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSansCond-Regular_1.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSansCond-Regular_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans Cond';
    src: url('custom/fonts/OptumSans/OptumSansCond-Bold_2.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSansCond-Bold_2.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSansCond-Bold_2.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans XBold';
    src: url('custom/fonts/OptumSans/OptumSans-XBold_2.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSans-XBold_2.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSans-XBold_2.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans';
    src: url('custom/fonts/OptumSans/OptumSans-BoldItalic_2.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSans-BoldItalic_2.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSans-BoldItalic_2.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans';
    src: url('custom/fonts/OptumSans/OptumSans-Bold_2.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSans-Bold_2.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSans-Bold_2.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans Cond';
    src: url('custom/fonts/OptumSans/OptumSansCond-Regular_2.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSansCond-Regular_2.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSansCond-Regular_2.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans Cond';
    src: url('custom/fonts/OptumSans/OptumSansCond-RegularItalic_2.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSansCond-RegularItalic_2.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSansCond-RegularItalic_2.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans';
    src: url('custom/fonts/OptumSans/OptumSans-Regular_2.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSans-Regular_2.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSans-Regular_2.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans';
    src: url('custom/fonts/OptumSans/OptumSans-RegularItalic_2.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSans-RegularItalic_2.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSans-RegularItalic_2.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optum Sans Cond';
    src: url('custom/fonts/OptumSans/OptumSansCond-BoldItalic_2.woff2') format('woff2'),
        url('custom/fonts/OptumSans/OptumSansCond-BoldItalic_2.woff') format('woff'),
        url('custom/fonts/OptumSans/OptumSansCond-BoldItalic_2.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}



body {
    background-color: #F3F3F3;
    font-family: Optum Sans;
    font-size:22px;
    color: #222222;
    background-repeat: no-repeat;
    margin: 0;
    line-height:1.2;
   
  }